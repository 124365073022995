
.container-principal-home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #fce51a;
}
.container-secundario-home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 /*   position: relative;*/
    background: #fce51a;
}
.encabezado-home {
    position: absolute; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    /* height: 260px; 300px le da altura al contenedor padre de espacios-elementos para luego poner posicion sticky al hijo  */
    top: 10px;

    height: 200px;
}

.container-foto-home {
    display: flex;
    flex-direction: column;
    border-radius: 12px 12px 12px 12px;
    cursor: pointer;
    position: relative;
    margin-bottom: 10px;
    width: min-content;
}

.encabezado-comida-alternativo {
    position: absolute; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 1;
    /* height: 260px; 300px le da altura al contenedor padre de espacios-elementos para luego poner posicion sticky al hijo  */
    top: 10px;

    height: 200px;
}

.espacio-elementos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1100px;

    position: sticky; /* se pone posicion sticky al hijo  y al padre posicion absolute y una altura especifica */
    top: 5px;
}

.precio-alternativo {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 3px 8px 3px 8px;
    color: #4D4D4D;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
}   

.texto-encabezado-home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
    color: #FFFFFF;
    z-index: 2;
}

.container-imagen-cabecera-alternativo {
    width: 100%;
    height: 312px;
    /* position: absolute; */
    /* display: flex; */

}

.container-options-home {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    margin-left:5px;
    margin-right:5px;
    margin-bottom: 100px;
    margin-top: 15px;

}
.container-new-principal-home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.head-new-home {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px 0px 24px 24px;
}

.button-new-home {
    border-radius: 12px;
    border: 2px solid #53596e;
    display: flex;
    height: 48px;
    width: -webkit-fill-available;
    margin-bottom: 15px;
    background: #ffffff;
}

.button-img-new-home {
    margin-top: 6px;
    margin-left: 10px;
    width: 36px;
}
.button-title-new-home {
    padding: 12px 40px 12px 40px;
    cursor: pointer;
    font-size: 14px;
    width: -webkit-fill-available;
    font-weight: 700;
    color: #28304A;
    text-decoration: none;
}

.container-titulo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.titulo-plato {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #4D4D4D;

    /*  */
    margin: 0px;
}

.informacion {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    color: #4D4D4D;
}

.parrafo {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.container-info {
    margin-bottom: 15px;
}

.subtitulosOpciones {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    color: #4D4D4D;
}

/* input[type="radio"] {
    /* accent-color:  aqua; */
    /* position: static; */
    /* display: none; 
} */

/* desaparece el input tipo radio por defecto */
/* input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
} */

/* .options-input {
    /* background-color: #FF007E;   no funciona el color aqui  
    
    right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}  */

/* LABEL */
.options-comida {
    background: #F3F2F4;
    border-radius: 12px 12px 12px 12px;
    cursor: pointer;
    position: relative;

    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px; */

    display: flex;
    justify-content: space-between;   
}

/* label:nth-last-child(1) {
    padding-right: 0;
}
 */
/* Label before */
/* label::after {
    content: "";
    border: 2px solid #4D4D4D;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-left: 50;
    /* margin: -8px 20px; */
    /* position: relative;

    
} */


/* label::after {
    content: "";
    display: inline-block;
    /* position: absolute; */
    /* position: static; */
    /* width: 10px;
    height: 10px;
    background: #ff0080; */
    /* left: 7px;
    top: 32px; */
    /* margin: 8px 50px; */
    /* margin-left: 0; */
    /* border-radius: 50%; */

   
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; 
} */




.container-btn-flotante {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    margin: 0px;
}

.footer {
   /* position: fixed;  posicion fija en la pantalla */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 25px 25px 0px 0px;
    padding: 15px 15px 15px 15px;
    background: #FFFFFF;
    box-shadow: 1px 1px 2px 2px #11224126;
    /* position: absolute; */
}

.img-btn-flotante {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
    cursor: pointer; 
    width: 73px; 
    height: 70px;
}


.container-precio {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    background: #FFFFFF;
}

.numeroContador {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-end;
    position: absolute;
}

.container-btn-carrito {
    border: none;
}

.fila-option-detalle-alternativo {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    background: #F3F2F4;
    border-radius: 12px 12px 12px 12px;
    cursor: pointer;
    padding: 10px;
    /* align-items: center; */
    /* gap: 10px; */
    margin-bottom: 10px;
}

.etiquetaTexto {
    color: white;
    border-radius: 12px;
    position: relative;
    right: 160px;
    bottom: 60px;
    padding: 5px;
    font-size: 10px;
    font-weight: 630;
    width: fit-content;
    margin: 1px;
}


.etiqueta-texto-3 {
    color: white;
    font-weight: 600;
    font-size: 12px;

    border-radius: 12px 12px 12px 12px;
    padding: 4px;
    width: fit-content;

    display: flex;
    justify-content: flex-start;
}

/* esta igual */
.grupo-etiquetas-descuentos {
    display: flex;
    /* flex-direction: row; */
    border-radius: 12px 12px 12px 12px;
    position: relative;
    /* position: absolute; */
    width: min-content;
}


.grupo-etiquetas-alternativo {
    display: flex;
    flex-direction: column;
    border-radius: 12px 12px 12px 12px;
    /* position: relative; */
    position: absolute;
    width: min-content;
    bottom: -85px;
}


.porcentajeDescuentoDetalle-Alternativo {
    color: white; 
    border-radius: 11px;
    position: relative;
    bottom: 20px;
    padding: 5px;
    font-size: 10px;
    font-weight: 630;
    bottom: 200px;
    /* width: fit-content; */

    /* left: -305px; */
    /* left: -265px; */
    gap: 1000px; 
}


.precio-tachado-rojo-foto {
    color: red;
    text-decoration:line-through;
    font-weight: 600;
    font-size: 16px;
    position: relative;
    left: 148px;
    top: -260px;
    border-radius: 12px 12px 12px 12px;
    padding: 4px;
    background: white;
    width: fit-content;
}

.precio-tachado-rojo-foto-1 {
    color: red;
    text-decoration: line-through;
    font-weight: 600;
    font-size: 14px;
    position: absolute;
    right: 1px;
    top: 40px;
    border-radius: 12px 12px 12px 12px;
    padding: 4px;
    background: white;
    width: -moz-fit-content;
    width: fit-content;
}


.porcentaje-descuento-detalle-2 {
    color: white;
    font-weight: 600;
    font-size: 12px;
    position: absolute;
    right: 2px;
    bottom: -75px;
    border-radius: 12px 12px 12px 12px;
    padding: 4px;
    width: -moz-fit-content;
    width: fit-content;
}



.container-precio-descuento-detalle { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* padding: 0px 125px 0px 125px;  */
    margin: 0 auto;
    /* se arregla con el padding en cada tamaño...  */
}

.precio-rojo-detalle-footer {
    color: red;
    text-decoration:line-through;
    font-weight: 600;
    font-size: 16px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
}

.precio-footer {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
}

.cardHome{
    display: flex; 
    border-radius: 5px;
    object-fit: cover; 
    border-radius: 5px;
    object-fit: cover;
    width: 160px;
    height: 160px;
}



/* 1150px */
@media (max-width: 1280px) {
    .footer  { 
        bottom: 10%;
        gap: 1000px; 
    } 
    .espacio-elementos {
        gap: 1080px;
    }
    .precio-tachado-rojo-foto{
        left: 575px
    }
    .precio-tachado-rojo {
        position: absolute;
        left: 155px;
    }
    .etiquetaTexto {
        right: 540px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
        left: 610px;
    }
}

@media (max-width: 1258px) {
    .footer  { 
        bottom: 10%;
        gap: 98px; 
    }

    .espacio-elementos {
        gap: 1060px;
    }

    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
}

@media (max-width: 1238px) {
    .footer  { 
        gap: 95px; 
    }

    .espacio-elementos {
        gap: 1040px;
    }

    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
        left: 155px;
    }
} 

@media (max-width: 1198px) {
    .footer  { 
        gap: 92px;
    }
    .espacio-elementos {
        gap: 1020px;
    }

    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
} 

@media (max-width: 1168px) {
    .footer  { 
        gap: 89px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-tachado-rojo {
        position: absolute;
    }
}


@media (max-width: 1220px) {
    .footer  { 
        bottom: 10%;
        gap: 86px; 
    }

    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
}

@media (max-width: 1180px) {
    .footer  { 
        bottom: 10%;
        gap: 89px; 
    }
    .espacio-elementos {
        gap: 1000px;
    }

    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
}


@media (max-width: 1160px) {
    .footer  { 
        bottom: 10%;
        gap: 89px; 
    }
    .espacio-elementos {
        gap: 990px;
    }

    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
}

@media (max-width: 1129px) {
    .footer  { 
        bottom: 10%;
        gap: 940px; 
    }
    .espacio-elementos {
        gap: 960px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
}

@media (max-width: 1130px) {
    .footer  { 
        gap: 80px; 
    }
    .espacio-elementos {
        gap: 940px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
}

@media (max-width: 1110px) {
    .espacio-elementos {
        gap: 900px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .footer  { 
        gap: 79px; 
    }
    .precio-tachado-rojo {
        position: absolute;
    }
}  

@media (max-width: 1090px) {
    .footer  { 
        gap: 82px; 
    }
    .espacio-elementos {
        gap: 880px;
    }

    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
}

@media (max-width: 1070px) {
    .footer  { 
        gap: 81px; 
    }
    .espacio-elementos {
        gap: 860px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
}

@media (max-width: 1050px) {
    .footer  { 
        gap: 80px; 
    }
    .espacio-elementos {
        gap: 840px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
    
}

@media (max-width: 1030px) {
    .footer  { 
        gap: 80px; 
    }
    .espacio-elementos {
        gap: 820px;
    }
    .precio-tachado-rojo-foto{
        left: 445px
    }
    .precio-tachado-rojo {
        position: absolute;
    }
    .etiquetaTexto {
        right: 410px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
        left: 480px;
    }
}


@media (max-width: 1020px) {
    .footer  { 
        gap: 76px; 
    }
    .espacio-elementos {
        gap: 800px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
    
}

@media (max-width: 1000px) {
    .footer  { 
        gap: 74px; 
    }
    .espacio-elementos {
        gap: 780px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
}

@media (max-width: 980px) {
    .footer  { 
        gap: 71px; 
    }
    .espacio-elementos {
        gap: 760px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }   
}

@media (max-width: 960px) {
    .footer  { 
        gap: 67px; 
    }
    .espacio-elementos {
        gap: 740px;
    }

    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo {
        position: absolute;
    }
}

@media (max-width: 940px) {
    .footer  { 
        gap: 62px; 
    }
    .espacio-elementos {
        gap: 720px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-tachado-rojo  {
        position: absolute;
    }
}

@media (max-width: 920px) {
    .footer  { 
        gap: 73px;
    }
    .espacio-elementos {
        gap: 700px;
    }
    
    .precio-tachado-rojo-foto  {
        position: absolute;
        left: 350px;
    }
    .etiquetaTexto {
        right: 384px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
        left: 386px;
    }
}

@media (max-width: 900px) {
    .footer  { 
        gap: 60px; 
    }
    .espacio-elementos {
        gap: 680px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-tachado-rojo-foto  {
        position: absolute;
        left: 320px;
    }
}

@media (max-width: 880px) {
    .footer  { 
        bottom: 10%;
        gap: 58px; 
    }
    .espacio-elementos {
        gap: 660px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 830px) {
    .footer  { 
        bottom: 10%;
        gap: 64px; 
    }
    .espacio-elementos {
        gap: 640px;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
    .etiquetaTexto {
        right: 352px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
        left: 356px;
    }
}

@media (max-width: 799px) {
    .footer  { 
        bottom: 10%;
        gap: 52px; 
    }
    .espacio-elementos {
        gap: 620px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 775px) {
    .footer  { 
        bottom: 10%;
        gap: 59px; 
    }
    .espacio-elementos {
        gap: 590px;
    }
    
    .precio-tachado-rojo-foto  {
        position: absolute;
        left: 297px;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
    .etiquetaTexto {
        right: 327px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
        left: 330px;
    }
}

@media (max-width: 740px) {
    .footer  { 
        bottom: 10%;
        gap: 47px; 
    }
    .espacio-elementos {
        gap: 563px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }

    .precio-rojo-detalle {
        position: absolute;
    }
}


@media (max-width: 720px) {
    .footer  { 
        bottom: 10%;
        gap: 45px; 
    }
    .espacio-elementos {
        gap: 540px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 702px) {
    .footer  { 
        bottom: 10%;
        gap: 43px; 
    }
    .espacio-elementos {
        gap: 520px;
    }
    
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 690px) {
    .footer  { 
        bottom: 10%;
        gap: 41px; 
    }
    .espacio-elementos {
        gap: 508px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 663px) {
    .footer  { 
        bottom: 10%;
        gap: 39px; 
    }
    .espacio-elementos {
        gap: 475px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}


@media (max-width: 645px) {
    .footer  { 
        bottom: 10%;
        gap: 37px; 
    }
    .espacio-elementos {
        gap: 480px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 624px) {
    .footer  { 
        bottom: 10%;
        gap: 37px; 
    }
    .espacio-elementos {
        gap: 450px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 619px) {
    .footer  { 
        bottom: 10%;
        gap: 34px; 
    }
    .espacio-elementos {
        gap: 430px;
    }
    
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}


@media (max-width: 590px) {
    .footer  { 
        bottom: 10%;
        gap: 31px; 
    }
    .espacio-elementos {
        gap: 400px;
    }
    
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 560px) {
    .footer  { 
        bottom: 10%;
        gap: 28px; 
    }
    .espacio-elementos {
        gap: 380px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 540px) {
    .footer  { 
        bottom: 10%;
        gap: 35px; 
    }
    .espacio-elementos {
        gap: 360px;
    }
    .precio-tachado-rojo-foto{
        left: 180px;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
    .etiquetaTexto {
        right: 214px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
        left: 217px;
    }
}

@media (max-width: 520px) {
    .footer  { 
        bottom: 10%;
        gap: 25px; 
    }
    .espacio-elementos {
        gap: 340px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 495px) {
    .footer  { 
        bottom: 10%;
        gap: 22px; 
    }
    .espacio-elementos {
        gap: 315px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 468px) {
    .footer  { 
        bottom: 10%;
        gap: 19px; 
    }
    .espacio-elementos {
        gap: 285px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 438px) {
    .footer  { 
        bottom: 10%;
        gap: 19px; 
    }
    .espacio-elementos {
        gap: 250px;
    }
    .precio-tachado-rojo-foto {
        left: 125px;
    }
    .etiquetaTexto {
        right: 155px;
    }
    .precio-rojo-detalle {
        position: absolute;
        left: 150px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
        left: 158px;
    }
}

@media (max-width: 410px) {
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
    .precio-tachado-rojo-foto {
        left: 150px;
    }
    .etiquetaTexto {
        right: 130px;
    }
}


@media (max-width: 395px) {
    .footer  { 
        bottom: 10%;
        gap: 15px; 
    }
    .espacio-elementos {
        gap: 220px;
    }
    .precio-tachado-rojo-foto {
        left: 110px;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
    .etiquetaTexto {
        right: 139px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
        /* left: 143px; */
        left: 323px;
    }
}

@media (max-width: 372px) {
    .footer  { 
        bottom: 10%;
        gap: 18px; 
    }
    .espacio-elementos {
        gap: 190px;
    }
    .precio-tachado-rojo-foto {
        left: 137px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}


@media (max-width: 360px) {
    .footer  { 
        bottom: 10%;
        gap: 18px; 
    }
    .espacio-elementos {
        gap: 190px;
    }
    .precio-tachado-rojo-foto {
        left: 97px;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
    .etiquetaTexto {
        right: 128px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
        left: 130px;
    }
}

@media (max-width: 343px) {
    .footer  { 
        gap: 60px; 
    }
    .espacio-elementos {
        gap: 140px;
    }
    .precio-tachado-rojo-foto {
        left: 98px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 310px) {
    .footer  { 
        bottom: 10%;
        gap: 30px; 
    }
    .espacio-elementos {
        gap: 130px;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
    .precio-tachado-rojo-foto {
        left: 58px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
        left: 92px;
    }
}

@media (max-width: 284px) {
    .footer  { 
        bottom: 10%;
        gap: 9px; 
    }
    .espacio-elementos {
        gap: 110px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
    .etiquetaTexto {
        right: 87px;
    }
}

@media (max-width: 260px) {
    .footer  { 
        bottom: 10%;
        gap: 4px; 
    }
    .espacio-elementos {
        gap: 90px;
    }
    .precio-tachado-rojo-foto {
        left: 87px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 243px) {
    .footer  { 
        bottom: 10%;
        gap: 4px; 
    }
    .espacio-elementos {
        gap: 60px;
    }
    .precio-tachado-rojo-foto {
        left: 73px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 220px) {
    .footer  { 
        bottom: 10%;
        gap: 4px; 
    }
    .espacio-elementos {
        gap: 40px;
    }
    .precio-tachado-rojo-foto {
        left: 62px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}

@media (max-width: 200px) {
    .footer  { 
        bottom: 10%;
        gap: 4px; 
    }
    .espacio-elementos {
        gap: 40px;
    }
    .porcentajeDescuentoDetalle-Alternativo {
        position: absolute;
    }
    .precio-rojo-detalle {
        position: absolute;
    }
}
.head-new-splash {
    width: 100%;
    height: 100%;
    object-fit: none;
}
