/* Menu.css */

.menu {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    z-index: 1000;
    overflow-x: auto;
    white-space: nowrap;
}

.menu ul {
    display: flex;
    justify-content: left;
    padding: 0;
    margin: 10px;
    list-style-type: none;
}

.menu ul li {
    margin: 0 15px;
}

.menu ul li a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    padding: 10px 0;
}

.menu ul li a.active {
    color: #f5a623;
    /* Color dorado similar al resaltado de la captura */
    border-bottom: 2px solid #f5a623;
}