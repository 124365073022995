
/* bannerPrueba */
.container-iconoMesa {
    display: flex;
    align-items: center;
}

.numeroMesa{
    color: #4D4D4D;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.iconoMesas {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}


