
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: white;
    height: 48px;
    margin: 20px;
}
.header1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: white;
    height: 48px;
    margin-bottom: 10px;
}
.center {
    flex: 2;
    text-align: center;
}
.header-logo{
    height: 34px;
    width: 34px;
    padding: 7px;
}

.cart {
    height: 48px;
    width: 48px;
    text-align: end;
}
.header-boton-cart{
    height: 34px;
    width: 34px;
    padding: 7px;
}
.header-company-logo{
    height: 48px; 
}
.header-arrow-left{
    height: 40px;
    width: 40px;
    padding: 4px;
}

.search-input-container {
    display: flex;
    align-items: center;
    width: 100%; /* Ajusta el ancho según tus preferencias */
    border-radius: 12px;
    overflow: hidden;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 48px;
    background-color: #F4F4F6;
  }
  
  .search-input {
    flex: 1;
    padding: 8px;
    border: none;
    outline: none;
    background-color: #F4F4F6;
  }
  
  .search-icon {
    height: 24px;
    width:  24px;
    margin: 12px;
    cursor: pointer;
  }
  .header-quantity-cart {
    background: #FF007E;
    color: white;
    border-radius: 100%;
    width: 20px;
    text-align: center;
    height: 20px;
    position: absolute;
    top: 14px;
    font-size: 13px;
    font-weight: 700;
    align-content: center;
    right: 14px;
    display: flex;
    align-items: center;
}
.header-quantity-cart-1{
    width: 20px;  
}

.header3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: white;
    height: 100px;
   
    background-position: center center;
    background-size: cover;
}