
.container-principal-footer-home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* background: #F3F2F4; */
    padding: 16px 32px 16px 32px;
    border-radius: 25px 25px 0px 0px;
    background: #FFFFFF;
  

    box-shadow: 1px 1px 2px 2px #11224126;

}

  /* box-shadow: 0px -8px teal; */

    /* box-shadow: 0px -2px 5px 0px #6D81A726; */

    /* box-shadow: 0px -2px 4px 0px #8997b136; */
    /* box-shadow: 0px -2px 10px 0px #b6b7bb26; */

    
.container-botones-footer-home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px 20px 0px 0px;
    gap: 10px;
    /* background: #F3F2F4; */

    width: 100%;  

    /* background: #2f025c; */
}


.container--footer-home {
    position: fixed;
    bottom: 0%;
    left: 0%;
    right: 0%;
    /* transform: translateX(-50%); */
}


.botones-flotantes {
    width: 40px;
    height: 48px;
}


