
.form-agregar-tarjeta{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    gap: 15px;
}

.container-datos-tc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin: 8px 20px 0px 20px; */
    padding: 11px;
    gap: 10px;
}

.inputDatosTC {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 75px;
    height: 36px;
    padding-left: 9px;
    border-radius: 12px;
    gap: 12px;
    background: #F3F2F4;
    border-style: none;
    outline: none;
    border: 1px solid #bdbdbd;
    /* border: 1px solid #4d4d4d; */
}

