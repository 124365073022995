
/* grilla alimentos */
.container-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-template-columns: 1fr 8fr 1fr;
    justify-content: center;
    gap: 12px;
    margin-bottom: 70px;
}

.container-foto-alternativo {
    display: flex;
    flex-direction: column;
    /* background: #F3F2F4; */
    border-radius: 12px 12px 12px 12px;
    cursor: pointer;
    position: relative;
    /* margin-bottom: 10px; */
    width: min-content;
}

.fotoComida {
    display: flex; 
    border-radius: 5px;
    object-fit: cover; 
    border-radius: 5px;
    object-fit: cover;
}

.contenedor-precio-comida {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    margin-bottom: 10px;
    width: min-content;
}

.grupo-etiquetas {
    display: flex;
    /* flex-direction: row; */
    border-radius: 12px 12px 12px 12px;
    /* position: relative; */
    /* position: absolute; */
    /* ancho minimo de un elemento */
    /* width: min-content; */
    align-items: end;
    justify-content: flex-end;
    
}


.etiquetaAzul {
    color: white;
    border-radius: 12px;
    position: relative;
    padding: 5px;

    top: -225px;
    left: -5px;
    font-size: 10px;
    font-weight: 630;
    
    /* width: fit-content; */
    display: flex;
    align-items: end;
}

.nombre-plato {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #4D4D4D;
    font-weight: 700;
    font-size: 14px;
    /* height: auto; */
    /* overflow: hidden; */
    /* word-break: break-all; */
    /* margin: 0 0 1em 1em; */
    width: fit-content;
}


.btn-mas-grilla {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: white;
    border-radius: 5px;
    margin: 3px;
}

.container-nombre-boton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    /* width: auto; */
    height: auto;
    background: #F3F2F4;
    border-radius: 0px;
}

.cantidad-seleccionada-foto-producto-grilla {
    background: #FF007E;
    color: white;
    border-radius: 100%; 
    width: 19px;
    border: none;
    position: absolute;
    left: 10px;
    top: 10px;
}


.fotoComida {
    border-radius: "5px"; 
    object-fit: "cover";
    border-radius: 12px;
}


.precio-rojo {
    color: red;
    text-decoration:line-through;
    font-weight: 600;
    font-size: 13px;
}


.precio-producto {
    font-size: 15px;
    color: #4D4D4D;
    font-weight: 700;
    width: fit-content;
}

.container-precio-descuento {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* padding: 2px 5px 5px 5px; */
    /* width: auto; */
    height: auto;

    padding: 2px 12px 5px 10px;
    background: #F3F2F4;
    border-radius: 0px 0px 12px 12px;

}

.porcentajeDescuento {
    color: white;
    border-radius: 12px;
    /* position: relative; */
    left: 8px;
    /* top: 35px; */
    padding: 4px;
    font-size: 10px;
    font-weight: 630;
    width: fit-content;
}


/* /////////////////  PRUEBA  //////////////////////  */

@media (max-width: 2000px) {
    .container-grid {
        gap: 22px;
        padding: 0; 
        justify-content: space-between;
    }
    .fotoComida {
        width: 205px;
        height: 165px;
    }
    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: 200px; */
        left: -5px;
        /* top: 10px */
        /* bottom: -35px; */
    }

    .cantidad-seleccionada-foto-producto-grilla {
        top: 15px;
    }

    .porcentajeDescuento{
        /* position: relative; */
        position: absolute;
        /* top: -105px; */
        top: 135px;
    }
}

@media (max-width: 560px) {
    .container-grid {
        gap: 25px;
        padding: 0; 
        justify-content: space-between;
    }

    .fotoComida {
        width: 195px;
        height: 155px;
    }
    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: 186px; */
        left: -5px;
        top: -220px
    }
    .cantidad-seleccionada-foto-producto-grilla {
        top: 14px;
    }
    .porcentajeDescuento{
        position: absolute;
        /* top: 45px; */
        top: 125px;
    }
}


@media (max-width: 504px) {
    .container-grid {
        gap: 23px;
        padding: 0; 
        justify-content: space-between;
    }

    .fotoComida {
        width: 195px;
        height: 155px;
    }

    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: 166px; */
        left: -5px;
    }

    .cantidad-seleccionada-foto-producto-grilla {
        top: 18px;
    }

    .porcentajeDescuento{
        position: absolute;
        top: 125px;
    }
}


@media (max-width: 492px) {
    .container-grid {
        gap: 20px;
        padding: 0; 
        justify-content: space-between;
    }

    .fotoComida {
        width: 195px;
        height: 155px;
    }

    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: 165px; */
        /* left: 110px; */
        left: -5px;
    }

    .cantidad-seleccionada-foto-producto-grilla {
        top: 14px;
    }

    .porcentajeDescuento{
        position: absolute;
        top: 125px;
    }
}


@media (max-width: 465px) {
    .container-grid {
        gap: 10px;
        padding: 0; 
        justify-content: space-between;
    }

    .fotoComida {
        width: 170px;
        height: 130px;
    }

    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: 140px; */
        /* left: 85px; */
        top: -195px
        /* top: -185px; */
    }

    .cantidad-seleccionada-foto-producto-grilla {
        top: 15px;
    }

    .porcentajeDescuento{
        position: absolute;
        top: 100px;
    }
}


@media (max-width: 420px) {
    .container-grid {
        gap: 17px;
        padding: 0; 
        justify-content: space-between;
    }

    .fotoComida {
        width: 160px;
        height: 120px;
    }

    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: -35px; */
        left: -5px;
        top: -185px
    }

    .cantidad-seleccionada-foto-producto-grilla {
        top: 15px;
    }

    .porcentajeDescuento{
        position: absolute;
        top: 90px;
    }
}


@media (max-width: 410px) {
    .container-grid {
        gap: 10px;
        padding: 0; 
        justify-content: space-between;
    }

    .fotoComida {
        width: 160px;
        height: 120px;
    }

    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: 130px; */
        left: -5px;
    }

    .cantidad-seleccionada-foto-producto-grilla {
        top: 13px;
    }

    .porcentajeDescuento{
        position: absolute;
        top: 90px;
    }
}

@media (max-width: 390px) {
    .container-grid {
        gap: 20px;
        padding: 0; 
    }

    .fotoComida {
        width: 145px;
        height: 105px;
    }

    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: -35px; */
        left: -5px;
        top: -170px;
    }

    .nombre-plato {
        font-size: 13px;
    }

    .container-nombre-boton {
        padding: 5px 5px;
    }

    .cantidad-seleccionada-foto-producto-grilla {
        top: 9px;
    }

    .porcentajeDescuento{
        position: absolute;
        top: 75px;
    }
}


@media (max-width: 388px) {
    .container-grid {
        gap: 19px;
        padding: 0; 
        justify-content: space-between;
    }
    .fotoComida {
        width: 145px;
        height: 105px;
    }
    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: 140px; */
        left: -5px;
    }
    .nombre-plato {
        font-size: 13px;
    }
    .container-nombre-boton {
        padding: 5px 5px;
    }

    .cantidad-seleccionada-foto-producto-grilla {
        top: 9px;
    }

    .porcentajeDescuento{
        position: absolute;
        top: 70px;
    }
}



@media (max-width: 378px) {
    .container-grid {
        gap: 14px;
        padding: 0; 
    }
    .fotoComida {
        width: 145px;
        height: 105px;
    }
    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: 130px; */
        left: 20px;
    }
    .nombre-plato {
        font-size: 13px;
    }
    .container-nombre-boton {
        padding: 5px 5px;
    }
    .cantidad-seleccionada-foto-producto-grilla {
        top: 9px;
    }
    .porcentajeDescuento{
        position: absolute;
        top: 70px;
    }
}

@media (max-width: 379px) {
    .container-grid {
        gap: 15px;
        padding: 0; 
        justify-content: space-between;
    }

    .fotoComida {
        width: 145px;
        height: 105px;
    }
    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: -35px; */
        left: -5px;
        top: -170px;
    }
    .nombre-plato {
        font-size: 13px;
    }
    .container-nombre-boton {
        padding: 5px 5px;
    }
    .cantidad-seleccionada-foto-producto-grilla {
        top: 9px;
    }
    .porcentajeDescuento{
        position: absolute;
        top: 75px;
    }
}


@media (max-width: 360px) {
    .container-grid {
        gap: 15px;
        padding: 0; 
        justify-content: space-between;
    }

    .fotoComida {
        width: 137px;
        height: 97px;
    }

    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: 130px; */
        left: -5px;
        top: -160px;
    }

    .nombre-plato {
        font-size: 11px;
    }

    .container-nombre-boton {
        padding: 5px 5px;
    }

    .cantidad-seleccionada-foto-producto-grilla {
        top: 10px;
    }

    .porcentajeDescuento{
        position: absolute;
        top: 67px;
    }
}


@media (max-width: 350px) {
    .container-grid {
        gap: 6px;
        padding: 0; 
        justify-content: space-between;
    }

    .fotoComida {
        width: 125px;
        height: 85px;
    }

    .etiquetaAzul {
        /* position: absolute; */
        /* bottom: 100px; */
        top: -145px;
        left: -5px;
    }

    .nombre-plato {
        font-size: 11px;
    }

    .container-nombre-boton {
        padding: 5px 5px;
    }

    .cantidad-seleccionada-foto-producto-grilla {
        top: 9px;
    }

    .porcentajeDescuento{
        position: absolute;
        top: 57px;
    }
}



@media (max-width: 310px) {
    .container-grid {
        gap: 3px;
        padding: 0; 
        justify-content: space-between;
    }
    .fotoComida {
        width: 115px;
        height: 75px;
    }
    .etiquetaAzul {
        /* position: absolute; */
        /* left: 35px; */
        left: -5px;
        font-size: 9px;
        top: -135px;
        /* bottom: 113px; */
    }
    .nombre-plato {
        font-size: 9.5px;
    }
    .container-nombre-boton {
        padding: 5px 5px;
    }
    .card-top > img {
        width: 80%;
        height: 80%;
    }
    .cantidad-seleccionada-foto-producto-grilla {
        top: 8px;
    }
    .porcentajeDescuento{
        position: absolute;
        top: 47px;
        font-size: 9.5px;
    }
    .precio-rojo {
        font-size: 11px;
    }
    .precio-producto {
        font-size: 11px;
    }
}