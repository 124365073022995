
.container-list-alternativo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    margin-bottom: 40px;
} 

 .container-second-lista-alternativa { 
    display: grid;
    grid-template-columns: 1fr 8fr;
}

 
.container-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
}

.btn-mas-alternativo {
    cursor: pointer;
    width: 25px;
    height: 25px;
}

.btn-menos {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

hr {
    border: 0.5px solid #EDEDED;
}

.container-informacion-list-alternativo {
    display: flex;
    flex-direction: column;
}

.informacion-list {
    color: #4D4D4D;
    font-size: 14px;

    /* Permite que se vean siempre dos lineas, oculta el texto si sobrepasa las dos lineas. */

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
}   


.container-end {
    display: flex; 
    flex-direction: column;
    align-items: flex-end; 
}

.container-botones {
    display: flex;
    flex-direction: row;
    align-items: flex-end; 
}


.precio-lista {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #4D4D4D;
    background: #F3F2F4;
    border-radius: 12px;
    font-size: 13px;
    margin-top: 4px;
    font-weight: 700;
    padding: 2px 7px;
}

.cantidad-seleccionada-carrito {
    background: #FF007E;
    color: white;
    border-radius: 100%; 
    width: 19px;
    border: none;
    position: fixed;
    bottom: 50px;
    left: 60%; 
    transform: translateX(-0%);
}


.cantidad-seleccionada-foto-producto-lista {
    background: #FF007E;
    color: white;
    border-radius: 100%; 
    width: 19px;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;
}

.container-btn-flotante-menu-list {
    position: fixed;
    /* bottom: 15%; */
    bottom: 5%;
    left: 85%;
    transform: translateX(-50%);
}

.boton-flotante {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
    cursor: pointer; 
    width: 66px; 
    height: 66px;
}


.fotoComidaLista {
    border-radius: "5px"; 
    object-fit: "cover";
    border-radius: 12px;
}

.precio-rojo-lista {
    color: red;
    text-decoration:line-through;
    font-weight: 600;
    font-size: 12px;
    /* padding: 1px 7px; */



    display: flex;
    flex-direction: column;
    align-items: center;
    /* color: #4D4D4D; */
    background: #F3F2F4;
    border-radius: 12px;
    margin-top: 4px;
    padding: 2px 7px;
}


.etiqueta-azul-lista {
    color: white;
    border-radius: 12px;
    padding: 2px 5px;
    font-size: 10px;
    font-weight: 630;
    width: fit-content;
    display: flex;
    align-items: end;
}


.porcentaje-descuento-lista {
    color: white;
    border-radius: 12px;
    /* position: relative; */
    position: absolute;
    right: 7px;
    top: 80px;
    padding: 4px;
    font-size: 9px;
    font-weight: 630;
    width: fit-content;
}

.container-foto-plato-lista {
    object-fit: cover;
    display: flex;
    position: relative;
}

.container-grupo-precios {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.grupo-etiquetas-lista {
    display: flex;
    border-radius: 12px 12px 12px 12px;
    align-items: end;
    top:  -60px;
}


.container-grupo-etiquetas {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 10px;
    position: absolute;
    left: 3px;
}

@media (max-width: 2000px) {
    .etiqueta-azul-lista {
        top: 10px;
    }
    .container-second-lista-alternativa { /*  */  /*  */  /*  */ 
        display: grid;
        grid-template-columns: 1fr 7fr;
        gap: 10px
    }
}

@media (max-width: 1040px) {
    .etiqueta-azul-lista {
        top: 10px;
    }
    .container-second-lista-alternativa { /*  */  /*  */  /*  */ 
        display: grid;
        grid-template-columns: 1fr 7fr;
        gap: 10px
    }
}

@media (max-width: 860px) {
    .container-second-lista-alternativa { /*  */  /*  */  /*  */ 
        display: grid;
        grid-template-columns: 1fr 7fr;
        gap: 10px
    }
}


 @media (max-width: 830px) {
    .container-second-lista-alternativa { 
        display: grid;
        grid-template-columns: 1fr 6fr ;
        gap: 10px
    }
}

@media (max-width: 730px) {
    .container-second-lista-alternativa { 
        display: grid;
        grid-template-columns: 1fr 6fr ;
        gap: 10px
    }
} 

@media (max-width: 640px) {
    .container-second-lista-alternativa { 
        display: grid;
        grid-template-columns: 1fr 6fr ;
        gap: 10px
    }
}

@media (max-width: 540px) {
    .container-second-lista-alternativa { 
        display: grid;
        grid-template-columns: 1fr 5fr ;
        gap: 10px
    }
}

@media (max-width: 415px) {
    .container-second-lista-alternativa { 
        display: grid;
        grid-template-columns: 1fr 5fr ;
        gap: 10px
    }
}
