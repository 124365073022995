input[type="radio"] {
    /* accent-color:  aqua; */
    position: static;
    /* position: relative;  */
}

.options-input {
    background-color: aqua;
    right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    /* position: relative;  */
}

/* .options-input {
    position: absolute;
    cursor: pointer;
    left: 300px;

    border-radius: 50%;
    border: black;
} */

/* input[type="radio"] {
    accent-color:  aqua;
    /* position: relative; 
} */

.fila-option-detalle-alternativo {
    background: #F3F2F4;
    border-radius: 12px 12px 12px 12px;
    cursor: pointer;
    padding: 10px;
    margin-bottom: 10px;
}

.options-comida {
    background: #F3F2F4;
    border-radius: 12px 12px 12px 12px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.titulo-categoria {
    margin-bottom: 5px;
}

.contenedor-cantidades {
    display: flex;
    flex-direction: row;
    margin-bottom: 11px;
}

.cantidades {
    font-size: 13px;
}