.container-principal-lista-ordenes  {
    display: flex;
    flex-direction: column;   
    /* position: relative; */
    /* min-height: 100vh;   */
}



.container-imagen-fondo {
    display: flex;
    flex-direction: column;
    margin: 0px;
    width: 100vw; 
    height: 100vh;
    background-repeat: no-repeat;
    /* background-image:  url("../Imagenes/map.png"); */
    /* width: 100%; */
}


/* backgroundImage: `url(${imgMapaFondo}` */

.container-lista-ordenes {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    /* margin-top: 20px; */
    margin: 0px 20px 0px 20px;
}


.head-lista-ordenes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 25px; */
    /* margin: 0px 20px 8px 20px; */
    margin-top: 30px;
    gap: 15px;

}

.boton-atras {
    background: White;
    border-radius: 8px;
}


.container-recorrido-real {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin-top: 45px;
    padding: 24px;
    gap: 16px;

    /* width: 320px;
    height: 370px; */
    /* padding: 16px 16px 24px 16px; */
    /* background: #bffcf59a; */

}

.linea-punteada2 {
    border: 0.5;
    background: rgba(237, 237, 237, 1);
    width: 90%;
    margin-left: 20px;
}

.container-pepido-recorrido {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    z-index: 1;
    border-radius: 20px 20px 0px 0px;
    padding: 16px 16px 20px 16px;
    height: 100%;
    height: 600px;
    /* min-height: 650px; */
    /* min-height: 0vh; */
    /* padding: 20px; */
    gap: 10px;
    /* background: #F3F2F4; */
    background: white;
    margin-top: auto;
    box-shadow: 0px 2px 20px 0px rgba(109, 129, 167, 0.685);  
    /* animation: entrarDesdeAbajo .8s ease; */
}

.container-pepido-recorrido.active {
    /* animation: entrarDesdeAbajo .8s ease; */
    /* transform: translateY(100px); */
    
    transform: translateY(390px);
    /* animation: entrarDesdeAbajo .8s ease;  */
    /* transform: translateY(-540px); */
}

.container-pepido-recorrido2 {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    z-index: 1;
    border-radius: 20px 20px 0px 0px;
    gap: 10px;
    /* background: #F3F2F4; */
    background: white;
    /* padding-top: 15px; */
    height: 380px;
    transform: translateY(20px);
    overflow: scroll;
    /* margin-top: auto; */
}

@keyframes entrarDesdeAbajo {
    from {
        transform: translateY(200px);
    }
    to {
        transform: translateX(0);
    }
}

.contenedor-imagen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imagen-barrita {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: #4D4D4D;
    width: 60px;
    /* height: 20px; */
    /* background: #07f436; */
}




.pedido {
    display: flex;
    flex-direction: column;
}

.title-pedido {
    margin: 0px;
    color: #4D4D4D;
}

.subtitle-pedido {
    margin: 0px;
}


p {
    margin: 0px;
}

.pedido-subtotal{
    display: flex;
    flex-direction: row;
    justify-content: space-between ;
    align-items: center;    
    /* margin: 15px; */
    margin: 15px 20px 55px 20px;

     /* margin: 55px; es el margin de la parte de abajo. */
}


.btn-siguiente-carrito {
    /* background: #00F2D7; */
    border-radius: 12px;
    border: none;
    padding: 12px 40px 12px 48px;
    cursor: pointer;
    margin: 60px  20px  15px 20px;
    display: flex;
    justify-content: center;
}

html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    position: relative;
}




.title-subtotal {
    font-size: 20px;
    color: #4D4D4D;
}


.subtitle-subtotal {
    font-size: 20px;
    margin: 0px;
    line-height: 24px;
    color: #4D4D4D;
}



.cantidad-seleccionada-foto-lista-pedido {
    background: #FF007E;
    color: white;
    border-radius: 100%; 
    width: 19px;
    border: none;
    position: absolute;
    left: 25px;
    margin-top: 14px;
}

.btn-mas-carrito {
    cursor: pointer;
    margin-left: 5px;
    margin-top: 8px;
    width: 25px;
    height: 25px;
}

.btn-menos-carrito {
    cursor: pointer;
    margin-top: 8px;
    width: 25px;
    height: 25px;
}


.lista-carrito {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    gap: 10px;
    margin: 0px 20px 0px 20px;
}

/* nueva lista de pedidos*/
.container--orden-actual {
    /* border: 1.4px solid #E6E6E6; */
    border-radius: 20px;
    padding: 16px 16px 24px 16px;
    gap: 16px;
    background: #BFFCF5;
    margin-top: 45px;
    /* box-shadow: 0px -30px 0px -10px #D2D2D2; */


    box-shadow:
        0px -30px 0px -6px #D2D2D2,
        0px -62px 0px -12px #F3F2F4,
        0px 0px 0px rgb(0,0,0, 0.5) inset;

    /* box-shadow: 0px -50px 0px -10px #F3F2F4; */
}

.container-recorrido {
    background: #FFFFFF;
    padding: 24px 16px 24px 16px;
    border-radius: 12px;
    /* height: 76px; */
    margin-top: 16px;
    /* margin-bottom: 16px; */
}

.flecha-derecha-fondo {
    background: #E6E6E6;
    /* background: #F9F8F9; */
    width: 24px;
    height: 24px;
    border-radius: 5px;
}

.numero-pedido{
    margin: 0px;
    padding: 5px;
    text-decoration: none;
    font-weight: 700;
    color: #4D4D4D;
}

.fecha-hora {
    /* font-family: 'Calibri'; */
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    /* color: #A9A9BA; */
    color: #4D4D4D;

    padding: 5px;
} 



.precio-orden{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    color: #4D4D4D;
    /* background: #F3F2F4; */
    border-radius: 12px;
    font-size: 13px;
    /* margin-top: 4px; */
    font-weight: 700;
    padding: 2px 7px;
    text-align: right;
}

/* Lista Ordenes... Nueva  */



.contenedor-historico-pedidos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    padding: 11px;
}


.contenedor-prueba2 {
    display: flex;
    align-items: center;
    gap: 10px;
}
.container-flecha {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}


.container-info-orden {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.estado-entrega {
    border-radius: 8px;
    padding: 8px;
    gap: 10px;
    background: #4D4D4D;
    color: #E6E6E6;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    /* width: 15%; */
    width: 50px;
}



/* Resumenpedido */

.container-total-resumen-pedido {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 130px;

    margin-top: auto;
    /* background: #F3F2F4; */
    z-index: 1;
    border-radius: 20px;
}



@media (max-width: 860px) {
    .lista-carrito { /*  */  /*  */  /*  */ 
        display: grid;
        grid-template-columns: 1fr 7fr 1fr;
        gap: 10px
    }
}


 @media (max-width: 830px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        gap: 10px
    }
}

@media (max-width: 730px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        gap: 10px
    }
} 

@media (max-width: 640px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        gap: 10px
    }
}

@media (max-width: 540px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
        gap: 10px
    }
}


@media (max-width: 415px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
        gap: 10px
    }
}