

.container-lista-tiendas {
    display: flex;
    flex-direction: column;
}

.container-agregar-tarjeta{
    display: flex;
    align-items: center;
    gap: 10px;
}

.img-agregar {
    /* background: green; */
    fill:rgb(109, 194, 28);
}

.titulo-agregar-tarjeta{
    display: flex;
    margin: 0px;
    text-align: initial;
}
.flecha-derecha{
    transform: scaleX(-1);
}

.titulo-ciudad{
    text-align: center;
}

.fin {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center; 
}
