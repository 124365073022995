.cabecera{
    width: 100%;
    background: #F3F2f4;
    /* padding: 1em; */

    display: flex;
    flex-flow:  wrap; /* Determina cuáles serán los ejes principal y transversal del contenedor. establece si los elementos se colocan en una sola línea o en varias.  */

    justify-content: space-between;
    align-items: center;
}

/* .cabecera-h1{}
.cabecera-a{} */

.cabecera-button{

    display: none;
}

.cabecera-svg{
    width: 2em;
    height: 2em;
    fill: aqua;
}

.cabecera-nav{
    background-color: antiquewhite;
}

.cabecera-ul{
    /* list-style:none; */
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: center;
}

.cabecera-li{
    list-style:none;     /* ELIMINAR LOS PUNTOS EN LAS ETIQUETAS «UL» Y «LI» */
    margin: 0 .5em;
}


@media screen and (max-width:480px) {
    
    .cabecera-button {
        display: flex;
    }
    .cabecera-nav {
        width: 90%;
        display: none;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }
    .cabecera-nav.isActive {
        display: flex;
    }
    .cabecera-ul {
        flex-direction: column;
        padding: 0px;
    }
    .cabecera-li {
        margin: .5em 0;
    }
}





/* ////////////  Nuevo menu /////////////// */

.navbar {
    /* background: #848484; */
    background: white;
    /* height: 60px; */
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
}

.nombreTienda {
    color:  #4D4D4D;    
    
}

.nav-logo {
    font-weight: bold;
    font-size: 20px;
    margin: 15px;
    /* color: #F3F2f4; */
    /* color: #848484; */
}

.nav-items p {
    margin: 15px;
    /* color: #F3F2f4; */
    color: #848484;
    position: relative;
    text-decoration: none;
}

.nav-icon-boton {
    display: none;
}


@media (max-width: 768px) {
    .nav-items {
        position: absolute;
        top: 60px;
        left: 0;      
        background: white;
        display: flex;
        flex-direction: column;
       /* align-items: center; */
        width: 60%;
        height: 100%;
        margin-left: 20px; 
        transform: translateX(-100%);
        transition: 0.3s ease all;
        text-decoration: none;
        z-index: 1;
        /* box-shadow: 100px 5px 5px rgba(44, 44, 44, 0.454); */
    }
    .nav-icon-boton {
        display: flex !important;
        flex-direction: column;
        margin: 5px;
    }

    .nav-items.open {
        transform: translateX(0) !important;
    }

    .nav-icon-boton span {
        /* width: 30px;
        margin-top: 5px;
        height: 4px; */
        width: 25px;
        height: 4px;
        margin-top: 3px;
        /* background: #F3F2f4; */
        background:  #848484;
        
        
        border-radius: 2px;
        transform-origin: 5px 0px;
        transition: all 0.2s linear;
    }

    /* Aqui se cambia el icono a una x para cerrar el menu */
    .nav-icon-boton.open > span{
        transform: rotate(45deg) translate(0px, 0px);
    }
    .nav-icon-boton.open > span:nth-child(2) {
        display: none;
    }
    .nav-icon-boton.open > span:nth-child(3) {
        transform: rotate(-45deg) translate(-5px, 1px);
    }
}
/* Aqui se muestra la Direccion */

.container-nav-direccion {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background: #F3F2F4;
    border-radius: 12px;
    padding: 12px 16px 12px 16px;
    padding: 7px;
    /* margin-top: 15px; */
}