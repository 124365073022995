.CarruselCategorias {
    width: 100%;
    margin: 0 auto;
    gap: 3px;
    margin-top: 15px;
}

.container-categorias{
    display: flex;
    flex-direction: row;
    /* background: green; */
    justify-content: center;
}

.slick-slider {  
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.nameCategorias {
    display: flex;
    justify-content: center;
    font-size: 10px;
    border-radius: 0px 0 12px 12px;
    text-align: center;
    align-items: center;
    height: 28px;
    margin: 0 auto;
}



/* contenedor de las categorias */
.card {  
    overflow: hidden;
    object-fit: cover;
    gap: 4px;
    display: flex;
    margin: 0 auto;
}

.card-top {
    width: 90px;
    height: 90px;
}

.card-top > img {
    display: flex;
    border-radius: 12px 12px 12px 12px;
    width: 48px;
    height: 48px;
    object-fit: cover;	
    margin: 0 auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    
} 

.card-top > h4 {
    margin: 0%;
}

.card-botton {
    margin: 5px 5px;
}


/* es el espacio entre cada contenedor del carrusel... */
.slick-slide > div {
    display: flex;
}
/* es el espacio entre cada contenedor del carrusel... */
.slick-list {
    margin: 0 -5px;
    display: flex;
    /* background: forestgreen; */
}

/* flechA DERECHA O SIGUIENTE */

/* .slick-prev.slick-disabled:before{
    color: black;
}

.slick-next.slick-disabled:before{
    color: black;
} */


.card-bottom {
    margin: 0px;
    align-items: center;
    text-align: center;
}


/* Color de las fechas */
.slick-prev:before {
    color: none;
    color: gray;
    filter: invert(108%); /* Esta opcion permite hacer el cambio de color de las flechas */
}

.slick-next:before {
    color: none;
    color: gray;
    filter: invert(108%); /* Esta opcion permite hacer el cambio de color de las flechas */
}

.new-name-categories {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 50px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    margin-top: 6px;
    color: #28304A;
}

.carrion-card-top {
    width: 160px;
    height: 120px;
}

.carrion-card-top > img {
    display: flex;
    border-radius: 12px 12px 12px 12px;
    width: 160px;
    height: 120px;
    object-fit: cover;	
    margin: 0 auto;
    align-items: center;
    align-content: center;
    justify-content: center;
} 
.carrion-name-categories {
    display: flex;
    max-height: 50px;
    font-size: 14px;
    font-weight: 700;
    padding: 8px;
}


.carrion-informacion {
    display: flex;
    flex-direction: column;
    color: #28304A;
    padding: 8px;
    font-weight: 700;
    font-size: 13px;
}

.carrion-card {  
    overflow: hidden;
    object-fit: cover;
    gap: 4px;
    display: flex;
    margin: 0 auto;
    width: 120px;
    max-width: 160px;
    height: 160px;
    background-color: #F4F4F6;
    margin-right: 10px;
    border-radius: 12px 12px 12px 12px;
    position: relative;
    display: inline-block; 
    
}
.carrion-tag {
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    border-radius: 12px;
    font-weight: 800;
}
.carrion-price {
    position: absolute;
    top: 80px;
    background-color:  white;
    color:  rgba(0, 0, 0, 0.7);
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    border-radius: 12px;
    font-weight: 800;
}
.carrion-discount {
    position: absolute;
    top: 0;
    right: 0; 
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    border-radius: 12px;
    font-weight: 800;
}
.carrion-label{
    margin-bottom: 10px; 
    display: flex;
    flex-direction: column;
    color: #28304A;
    font-weight: 700;
    font-size: 16px;
}








