.container-principal-carrito{
    display: flex;
    flex-direction: column;   
    /* position: relative; */
    /* min-height: 100vh;   */
}

.container-list-pedido {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* margin: 0px 20px 0px 20px; */
}


.head-carrito {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 25px; */
    margin: 0px 20px 8px 20px;
}

.pedido {
    display: flex;
    flex-direction: column;
}

.imagen-perfil {
    height: 35px;
    border-radius: 50%;
    /* width: 50%;
    height: 50%; */
}

.title-pedido {
    margin: 0px;
    color: #4D4D4D;
}

.subtitle-pedido {
    margin: 0px;
}

.container-boton-borrar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 20px 15px 20px;
}

.container-info-entrega {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3px;
}

p {
    margin: 0px;
}

.pedido-subtotal{
    display: flex;
    flex-direction: row;
    justify-content: space-between ;
    align-items: center;    
    margin: 15px 20px 55px 20px;
}


.btn-siguiente-carrito {
    /* background: #00F2D7; */
    border-radius: 12px;
    border: none;
    padding: 12px 40px 12px 48px;
    cursor: pointer;
    margin: 60px  20px  15px 20px;
    display: flex;
    justify-content: center;
}

html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    position: relative;
}

.fondo-boton {
    border-radius: 20px 20px 0px 0px;
    background: #F3F2F4;
    display: flex;
    flex-direction: column;
    width: 100%; 
    margin-top: auto;
} 


.title-subtotal {
    font-size: 20px;
    color: #4D4D4D;
}


.subtitle-subtotal {
    font-size: 20px;
    margin: 0px;
    line-height: 24px;
    color: #4D4D4D;
}


.container-subtotal {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: auto;
    background: #F3F2F4;
    z-index: 1;

}

.cantidad-seleccionada-foto-lista-pedido {
    background: #FF007E;
    color: white;
    border-radius: 100%; 
    width: 19px;
    border: none;
    position: absolute;
    left: 25px;
    margin-top: 14px;
}

.btn-mas-carrito {
    cursor: pointer;
    margin-left: 5px;
    margin-top: 8px;
    width: 25px;
    height: 25px;
}

.btn-menos-carrito {
    cursor: pointer;
    margin-top: 8px;
    width: 25px;
    height: 25px;
}


.lista-carrito {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    gap: 10px;
    margin: 0px 20px 0px 20px;
}

.container-orden-actual-detalle {
    border-radius: 20px;
    padding: 16px 16px 24px 16px;
    gap: 16px;
    background: #f443364d;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (max-width: 860px) {
    .lista-carrito { /*  */  /*  */  /*  */ 
        display: grid;
        grid-template-columns: 1fr 7fr 1fr;
        gap: 10px
    }
}


 @media (max-width: 830px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        gap: 10px
    }
}

@media (max-width: 730px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        gap: 10px
    }
} 

@media (max-width: 640px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        gap: 10px
    }
}

@media (max-width: 540px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
        gap: 10px
    }
}


@media (max-width: 415px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
        gap: 10px
    }
}

.leaflet-container {
    height: 50vh;
    width: 100%;
  }