
.container-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    /* width: 420px; */
    margin-bottom: 40px;
} 

 .container-second { 
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    /* gap: 5px; */
}

.container-foto-plato  {
    object-fit: cover;
    display: flex;
    align-items: center;
}
 
.container-title {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: flex-start;
    margin-left: 20px;
}

.btn-mas {
    /* margin-left: 33px; */
    cursor: pointer;
    margin-top: 12px;
    width: 25px;
    height: 25px;
}

.btn-menos {
    cursor: pointer;
    /* margin-left: 15px;
    margin-top: 17px; */
    width: 20px;
    height: 20px;
}

/* hr {
    border: 0.5px solid #EDEDED;
} */

.container-informacion-list {
    display: flex;
    flex-direction: column;
    /* align-items: start; */
    justify-content: center;
    /* padding-right: 480px; */
}

.informacion-list {
    /* display: flex;
    /* flex-direction: column; 
    flex-direction: row;
    align-items: start;
    color: #4D4D4D;
    font-size: 13px;
    margin-top: 15px; */

    color: #4D4D4D;
    font-size: 14px;
    

    /* Permite que se vean siempre dos lineas, oculta el texto si sobrepasa las dos lineas. */

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
}   


.container-end-lista {
    display: flex; 
    flex-direction: column;
    align-items: flex-end; 
}

.container-botones {
    display: flex;
    flex-direction: row;
    align-items: flex-end; 
}


.informacion-list-precio {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #4D4D4D;
    background: #F3F2F4;
    border-radius: 12px;
    font-size: 13px;
    margin-top: 4px;
    font-weight: 700;
    padding: 2px 7px;
}

.cantidad-seleccionada-carrito {
    background: #FF007E;
    color: white;
    border-radius: 100%; 
    width: 20px;
    padding: 2px;
    border: none;
    position: fixed;
    bottom: 50px;
    left: 60%; 
    transform: translateX(-0%);
}


.cantidad-seleccionada-foto-producto {
    background: #FF007E;
    color: white;
    border-radius: 100%; 
    width: 19px;
    border: none;
    position: absolute;
    left: 25px;
}

.container-btn-flotante-menu-list {
    position: fixed;
    bottom: 2%;
    left: 85%;
    transform: translateX(-50%);
}

.boton-flotante {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
    cursor: pointer; 
    width: 66px; 
    height: 66px;
}


@media (max-width: 860px) {
    .container-second { /*  */  /*  */  /*  */ 
        display: grid;
        grid-template-columns: 1fr 7fr 1fr;
        gap: 10px
    }
}


 @media (max-width: 830px) {
    .container-second { 
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        gap: 10px
    }
}

@media (max-width: 730px) {
    .container-second { 
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        gap: 10px
    }
} 

@media (max-width: 640px) {
    .container-second { 
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        gap: 10px
    }
}

@media (max-width: 540px) {
    .container-second { 
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
        gap: 10px
    }
}


@media (max-width: 415px) {
    .container-second { 
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
        gap: 10px
    }
}


/* @media (max-width: 574px) {
    .informacion-list { 
        font-size: 11px;
    }
}

@media (max-width: 516px) {
    .informacion-list { 
        font-size: 10px;
    }
}

@media (max-width: 480px) {
    .informacion-list { 
        font-size: 8px;
    }
} */