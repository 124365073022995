.App {

  /* display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* align-content: center; */
  /* text-align: center; */

  /* width: 320px;
  height: 568px; */

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin: 0px;
  /* margin: 20px 20px 20px 21px; */

}


.restaurant-info-container {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.restaurant-header {
  display: flex;
 /* align-items: center;*/
}

.restaurant-logo {
  width: 80px;
  height: 80px;
  border-radius: 20%;
  margin-right: 15px;
}

.restaurant-details {
  display: flex;
  flex-direction: column;
}

.restaurant-name {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.restaurant-rating {
  display: flex;
  align-items: center;
  font-size: 25px;

}

.icon-info,
.icon-share,
.icon-star {
  margin-right: 5px;
  margin-right: 15px;
}

.restaurant-delivery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.restaurant-delivery span {
  font-size: 14px;
}

.map-link {
  color: #333;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
}

.menu-3-section {
  padding-left: 20px;
  padding-right: 25px;
}