.container-principal-carrito{
    display: flex;
    flex-direction: column;   
    /* position: relative; */
    /* min-height: 100vh;   */
}

.container-list-pedido {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    /* margin: 0px 20px 0px 20px; */
}


.head-carrito {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 25px; */
    margin: 0px 20px 8px 20px;
}

.pedido {
    display: flex;
    flex-direction: column;
}

.title-pedido {
    margin: 0px;
    color: #4D4D4D;
}

.title-tipo--entrega {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #4D4D4D;
}

.subtitle-pedido {
    margin: 0px;
}

.container-body {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    justify-content: space-between;
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
}

.container--tipo--entrega {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* justify-content: flex-end;  */
    align-items: center;
    gap: 10px;
}

.caja-end { 
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 30px;
}

.container-info-detalle-pago {
    display: flex;
    flex-direction: row;
    gap: 9px;
    /* align-items: center; */
    margin: 0px 10px 0px 10px;
}


.container-sub-titulos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 1.4px solid #E6E6E6; */
    background: #F3F2F4;
    border-radius: 12px;
    padding: 12px 16px 12px 16px;
    margin-top: 15px;
}

.titulos-h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #4D4D4D;
}

.subtitulos {
    /* font-family: Objektiv Mk2; */
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #4D4D4D;
    ;
}

.boton-metodo-pago{
    width:  56px;
    height:  56px;
    border-radius: 12px;
    border: none;
    gap: 8px;
    background: #F3F2F4;
    cursor: pointer;
}

.img-metodo-pago{

    display: block; /* Hace que la imagen sea un bloque para poder aplicar márgenes automáticos */
    margin: auto; 
}

.boton-metodo-pago{
    width:  56px;
    height:  56px;
    border-radius: 12px;
    border: none;
    gap: 8px;
    background: #F3F2F4;
    cursor: pointer;

}

.title-metodo-pago {
    font-weight: 500;
    font-size: 12px;    
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 10px;
    min-width: 50px;
}

.boton-porcentaje {
    width: 54px;
    height: 32px;
    border: none;
    border-radius: 8px;

    border: 1px solid #4D4D4D;
    padding: 8px;
    gap: 10px;
    cursor: pointer;
    /* color: #4D4D4D; */
    background: white;
}

.linea-punteada {
    width: 95%;
    height: 0;
    border: medium none;
    border-top: 1.5px dashed #A6A6A6 !important;
}


.container--detalle-pago{
    border: 0px solid #E6E6E6;
    border-radius: 5px;
    padding: 11px;
    margin-top: 10px;
    margin-bottom: 35px;
    margin-left: 0px;
    margin-right: 0px;
    background: #FFFFFF;
    background-image: url("../Imagenes/fondo_papel.svg"); 
    padding-top: 20px;
}

.tipo--entrega {
    border-radius: 8px;
    padding: 8px;
    gap: 10px;
    background: #4D4D4D;
    color: #E6E6E6;
    
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;

}

.tipo--entrega.active {
    background: #E6E6E6;
    color: #4D4D4D;
}


p {
    margin: 0px;
}

.pedido-subtotal{
    display: flex;
    flex-direction: row;
    justify-content: space-between ;
    align-items: center;    
    /* margin: 15px; */
    margin: 15px 20px 55px 20px;

     /* margin: 55px; es el margin de la parte de abajo. */
}


.btn-siguiente-carrito {
    /* background: #00F2D7; */
    border-radius: 12px;
    border: none;
    padding: 12px 40px 12px 48px;
    cursor: pointer;
    margin: 60px  20px  15px 20px;
    display: flex;
    justify-content: center;
}

html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    position: relative;
}

.fondo-boton-detail {
    border-radius: 20px 20px 0px 0px;
    background: #FFFFFFF;
    display: flex;
    flex-direction: column;
    width: 100%; 
    margin-top: auto;
   
} 


.title-subtotal {
    font-size: 20px;
    color: #4D4D4D;
}




.subtitle-subtotal {
    font-size: 20px;
    margin: 0px;
    line-height: 24px;
    color: #4D4D4D;
}


.container-subtotal {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: auto;
    background: #F3F2F4;
    z-index: 1;

}

.cantidad-seleccionada-foto-lista-pedido {
    background: #FF007E;
    color: white;
    border-radius: 100%; 
    width: 19px;
    border: none;
    position: absolute;
    left: 25px;
    margin-top: 14px;
}

.btn-mas-carrito {
    cursor: pointer;
    margin-left: 5px;
    margin-top: 8px;
    width: 25px;
    height: 25px;
}

.btn-menos-carrito {
    cursor: pointer;
    margin-top: 8px;
    width: 25px;
    height: 25px;
}


.lista-carrito {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    gap: 10px;
    margin: 0px 20px 0px 20px;
}

.boton-cancelar {    
    border-radius: 12px;
    padding: 12px 40px 12px 40px;
    cursor: pointer;
    display: flex;
    color: #000000;
    border: 1px solid #000000;
    background: rgba(255, 0, 0, 0);
  }



@media (max-width: 860px) {
    .lista-carrito { /*  */  /*  */  /*  */ 
        display: grid;
        grid-template-columns: 1fr 7fr 1fr;
        gap: 10px
    }
}


 @media (max-width: 830px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        gap: 10px
    }
}

@media (max-width: 730px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        gap: 10px
    }
} 

@media (max-width: 640px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        gap: 10px
    }
}

@media (max-width: 540px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
        gap: 10px
    }
}


@media (max-width: 415px) {
    .lista-carrito { 
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
        gap: 10px
    }
}
.new-type-pay {
    font-size: 16px;
    font-weight: 700;
    color: #28304A;
    margin-top: 20px;
    margin-bottom: 20px;
}
.new-button-pay {
    border-radius: 12px;
    border: none;
    padding: 12px 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: white;
    background: #28304A;
    width: 148.5px;
    font-size: 14px;
    font-weight: 500;
}
.new-cancel-pay {
    border-radius: 12px;
    padding: 12px 40px 12px 40px;
    cursor: pointer;
    display: flex;
    color: #28304A;
    border: 1px solid #28304A;
    background: rgba(255, 0, 0, 0);
    width: 148.5px;
    font-size: 14px;
    font-weight: 500;
}