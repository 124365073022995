
.containerPrincipalMesa {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    margin-top: 60px;
}


.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 45px; */
    gap: 15px;
}

.inputConfirmacion {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 270px;
    height: 36px;
    padding-left: 9px;
    border-radius: 12px;
    gap: 12px;
    background: #F3F2F4;
    border-style: none;
    outline: none;
    border: 1px solid #e7e6e6; 
} 

.element-Confirmacion {
    display: flex;
    flex-direction: column; 
    align-items: center;
    align-content: center;
    border: 1.3px solid #e7e6e6; 
    width: 300px;
    border-top: none;
    border-radius: 0px 0px 5px 5px;
    padding: 13px 0px 13px 0px;
}



.confirmacion {
    font-family: 'Bahnschrift SemiBold';
    font-style: normal; 
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
}


.containerConfirmacion {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1.3px solid #E6E6E6;
    border-radius: 5px 5px 0px 0px;
    padding: 11px;
    width: 278px;
    align-items: center;
    border-bottom: none;
}

.label {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    align-content: start;
    text-align: left;
}


.css-1vom5ua-control {
    width: 280px;
}

.css-b62m3t-container{
    width: 280px;
}

/* ////  Nuevo */

.css-1f43avz-a11yText-A11yText{
    width: 280px;
}

.css-1fdsijx-ValueContainer{
    width: 280px;
}

.css-qbdosj-Input {
    width: 100%;
}


.css-zwdhrg-menu {
    width: 280px;
}


/* .css-1fdsijx-ValueContainer {
    width: 245px;
} */

